import {App} from "vue";
import {VerificationPhonePayload} from '@/types'

class ApiController {
    apiURL: string;
    timeoutSeconds: number;

    constructor() {
        this.apiURL = '/api/';
        this.timeoutSeconds = 30;
    }

    //////////////////////////// Endpoints ////////////////////////////

    async verification(emailKey: string): Promise<Record<string, unknown> | Response> {
        return this._GET(`emailverification/${emailKey}`);
    }

    async phoneNumberVerification(emailKey: string, payload: VerificationPhonePayload): Promise<Record<string, unknown> | Response> {
        return this._POST(`phoneNumberVerification/${emailKey}`, JSON.stringify(payload), true);
    }

    async smsCodeVerification(id: number, payload: VerificationPhonePayload): Promise<Record<string, unknown> | Response> {
        return this._POST(`smsCodeVerification/${id}`, JSON.stringify(payload), true);
    }

    async sendSmsCodeAgain(emailKey: string): Promise<Record<string, unknown> | Response> {
        return this._POST(`sendSmsCodeAgain/${emailKey}`, JSON.stringify({}));
    }

    //////////////////////// End of Endpoints /////////////////////////

    /////////////////////// Internal functions ////////////////////////

    async _GET(endpoint: string, return400ResponseError = false): Promise<Record<string, unknown> | Response> {
        const init = {
            method: 'GET',
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            }
        }

        return this._fetchWithTimeout(endpoint, init, this.timeoutSeconds * 1000, return400ResponseError);
    }

    async _POST(endpoint: string, data: string, return400ResponseError = false): Promise<Record<string, unknown> | Response> {
        const init = {
            method: 'POST',
            body: data,
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }

        return this._fetchWithTimeout(endpoint, init, this.timeoutSeconds * 1000, return400ResponseError);
    }

    timeoutPromise(timeout: number, err: Error, promise: Promise<Response>) {
        return new Promise(function (resolve, reject) {
            promise.then(resolve, reject);
            setTimeout(reject.bind(null, err), timeout);
        });
    }

    _fetchWithTimeout(endpoint: string, options: Record<string, unknown> = {}, timeout = 30000 ,return400ResponseError = false) {
        return this.timeoutPromise(timeout, new Error('Timed Out!'), fetch(this._getURL(endpoint), options))
            .then((response: Response | unknown) => {
                return this._getResponse(response, return400ResponseError);
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    }

    _getURL(endpoint: string): string {
        if (this.apiURL.endsWith("/"))
            this.apiURL = this.apiURL.slice(0, -1) + '';

        if (!endpoint.startsWith("/"))
            endpoint = "/" + endpoint;

        return this.apiURL + endpoint;
    }

    // TODO: dodać zwracany typ
    async _getResponse(fetchResponse: Response | unknown, return400ResponseError = false): Promise<Record<string, unknown> | Response> {
        let _fetchResponse: Response;
        const badResponseErrorMessage = "Bad response from server";

        if (!fetchResponse || (fetchResponse instanceof Response)) {
            _fetchResponse = fetchResponse as Response;

            if (!_fetchResponse.body || !_fetchResponse.ok || (_fetchResponse.status >= 400 && _fetchResponse.status < 600)) {
                if (_fetchResponse.status === 400 && return400ResponseError) {
                    return _fetchResponse;
                }

                if (_fetchResponse.status && _fetchResponse.statusText)
                    console.error("AdminPanelApiController->_getResponse error: " + _fetchResponse.status + " - " + _fetchResponse.statusText);

                throw new Error(badResponseErrorMessage);
            }

            const responseBody = (_fetchResponse.body instanceof ReadableStream) ? await this.readResponseBody(_fetchResponse.body) : _fetchResponse.body;

            return (Object.prototype.toString.call(responseBody) === "[object String]") ? JSON.parse(responseBody) : responseBody;
        }

        throw new Error(badResponseErrorMessage);
    }

    readResponseBody(responseBody: ReadableStream) {
        const reader = responseBody.getReader();

        const stream = new ReadableStream({
            start(controller) {
                // The following function handles each data chunk
                function push() {
                    // "done" is a Boolean and value a "Uint8Array"
                    reader.read().then(({done, value}) => {
                        // If there is no more data to read
                        if (done) {
                            controller.close();
                            return;
                        }

                        // Get the data and send it to the browser via the controller
                        controller.enqueue(value);
                        push();
                    })
                }

                push();
            }
        });

        return new Response(stream, {headers: {"Content-Type": "text/html"}}).text();
    }

    /////////////////// End of Internal functions /////////////////////
}


export default {
    install: (app: App): void => {
        app.config.globalProperties.$apiController = new ApiController();
    }
}
