import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
    onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
  }, _toDisplayString(_ctx.title), 33))
}