import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  phoneNumber(state: State): string,
};

export const getters: GetterTree<State, State> & Getters = {
  phoneNumber: (state) => state.phoneNumber,
  orderId: (state) => state.orderId,
  openCode: (state) => state.openCode,
  userData: (state) => state.userData,
};
