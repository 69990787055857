import { RouteRecordRaw } from 'vue-router';
import { ROUTING_URLS } from './routings';
import Home from '../views/Home.vue';
import Email from '../views/Email.vue';
import NotFound from '../views/NotFound.vue';
import SmsNumber from "@/views/SmsNumber.vue";
import FinalInformation from "@/views/FinalInformation.vue";
import PaymentConfirmation from "@/views/PaymentConfirmation.vue";
import PaymentFailed from "@/views/PaymentFailed.vue";
import Regulations from "@/views/Regulations.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTING_URLS.ANYTHING,
    name: 'notFound',
    component: NotFound,
  },
  {
    path: ROUTING_URLS.HOME,
    name: 'home',
    component: Home,
  },
  {
    path: ROUTING_URLS.EMAIL,
    name: 'email',
    component: Email,
  },
  {
    path: ROUTING_URLS.ANYTHING,
    name: 'smsNumber',
    component: SmsNumber,
  },
  {
    path: ROUTING_URLS.ANYTHING,
    name: 'finalInformation',
    component: FinalInformation,
  },
  {
    path: ROUTING_URLS.PAYMENT_CONFIRMATION,
    name: 'paymentConfirmation',
    component: PaymentConfirmation,
  },
  {
    path: ROUTING_URLS.PAYMENT_FAILED,
    name: 'paymentFailed',
    component: PaymentFailed,
  },
  {
    path: ROUTING_URLS.REGULATIONS,
    name: 'regulations',
    component: Regulations,
  },
];

export default routes;
