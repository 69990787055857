
  import {defineComponent} from 'vue';

  const Button = defineComponent({
    name: 'ButtonComponent',
    props: {
      action: {
        type: Function,
        default: null,
      },
      title: {
        type: String,
        default: '',
      },
    },
  });
  export default Button;
