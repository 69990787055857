export default function formatData(time: string, type: string): string {
    let options = {};

    if (time) {
        switch (type) {
            case 'date':
                options = {year: 'numeric', month: 'numeric', day: 'numeric'};

                return new Date(time).toLocaleDateString("pl", options);
            case 'time':
                options = {hour: 'numeric', minute: 'numeric'};

                return new Date(time).toLocaleTimeString("pl", options);
            default:
                options = {year: 'numeric', month: 'numeric', day: 'numeric'};

                return new Date(time).toLocaleDateString("pl", options);
        }
    }

    return '';
}