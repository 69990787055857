export const state = {
  userData: {
    firstName: '',
    create: '',
    emailKey: '',
    phone: {
      last3: '',
    }
  },
  phoneNumber: '',
  orderId: null,
  openCode: null,
};

export type State = typeof state;
