
import {defineComponent} from "vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import {MutationTypes} from "@/store/mutation-types";
import {UserVerificationResponse} from '@/types'
import MainText from "@/components/MainText.vue";
import formatDate from "../helpers/helpers"

export default defineComponent({
  components: {
    PhoneNumber,
    MainText,
  },
  computed: {
    firstName(): string {
      return this.$store.getters.userData.firstName;
    },
    date(): string {
      return formatDate(this.$store.getters.userData.create, 'date');
    },
    time(): string {
      return formatDate(this.$store.getters.userData.create, 'time');
    },
  },
  mounted() {
    this.$apiController.verification(this.$route.params.email)
        .then((res: UserVerificationResponse): void => {
          this.$store.commit(MutationTypes.SET_USER_DATA, res);
        })
        .catch((): void => {
          this.$router.push({name: 'notFound'});
          this.$toast.error('Coś poszło nie tak... Spróbuj ponownie lub skontaktuj się z administratorem. ');
        });
  },
  beforeMount() {
    if (!this.$route.params.email) {
      this.$router.push({name: 'notFound'});
    }
  }
});
