import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290c418d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-container" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainText = _resolveComponent("MainText")!
  const _component_PhoneNumber = _resolveComponent("PhoneNumber")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MainText, null, {
        title: _withCtx(() => [
          (_ctx.firstName)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Cześć " + _toDisplayString(_ctx.firstName) + ",", 1))
            : _createCommentVNode("", true)
        ]),
        content: _withCtx(() => [
          (_ctx.date && _ctx.time)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, "miło nam, że chcesz skorzystać z naszego kluczykomatu. Twój serwis w Auto Wimar zaczyna się " + _toDisplayString(_ctx.date) + " o godzinie " + _toDisplayString(_ctx.time) + ", więc teraz jest najlepszy moment aby rozpocząć proces depozytu klucza. Żeby kontynuować potwierdź swój numer telefonu. ", 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_PhoneNumber)
    ])
  ]))
}